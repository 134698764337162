import { notification } from 'antd';
import { helpers, config, types } from '.';

export const signOutSuccess = (path = '/') => {
    notification.success({ message: 'Your token has expired' });
    helpers.delStorage('auth');
    setTimeout(() => {
        helpers.redirect(path);
    }, 200);
};

export const apnData = (obj: any) => {
    const body = new FormData();
    for (const p in obj) {
        body.append(p, obj[p]);
    }
    return body;
}

export const apiHeaders = (type = ''): {
    Accept: string;
    Authorization: string;
    'Content-Type'?: string;
    'x-ogateway-token'?: string;
} => {
    helpers.initialize();
    const env = helpers.isJson(helpers.getStorageJson('utils').env) ? JSON.parse(helpers.getStorageJson('utils').env) : '';
    const token = helpers.isJson(helpers.getStorageJson('auth').token) ? JSON.parse(helpers.getStorageJson('auth').token) : '';
    const business: types.Business = helpers.isJson(helpers.getStorageJson('auth').business) ? JSON.parse(helpers.getStorageJson('auth').business) : {};
    const businessKey: types.Keys = (business?.keys || [])[0];
    switch (type) {
        default:
            return {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${token}`,
                'x-ogateway-token': helpers.md5({ businessId: business.id }),
            };

        case 'file':
            return {
                'Accept': 'application/json',
                'Authorization': `${token}`,
                'x-ogateway-token': helpers.md5({ businessId: business.id }),
            };

        case 'dev':
            return {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${(env === 'test' ? businessKey.test_key : businessKey.live_key)}`,
            };
    }
}

const axiosURL = (action: string) => {
    const env = helpers.isJson(helpers.getStorageJson('utils').env) ? JSON.parse(helpers.getStorageJson('utils').env) : '';
    const empty = action.includes('http:') || action.includes('https:') ? true : false;
    const url = ((empty === false) ? (env === 'live' ? config.server : config.server) + action : action);
    return url;
}

export const get = async (action: string, data = {}) => {
    const headers = apiHeaders();
    try {
        const response = await fetch(axiosURL(action) + '?' + new URLSearchParams(data).toString(), {
            method: 'GET',
            headers,
        });
        return await respunse(response);
    } catch (error: any) {
        if (error.message === 'Failed to fetch') {
            // signOutSuccess();
        }
        return { status: 606, message: 'Network request failed', data: error };
    }
}

export const post = async (action: string, data: any = {}) => {
    const stage = helpers.isJson(helpers.getStorageJson('utils').env) ? JSON.parse(helpers.getStorageJson('utils').env) : '';
    const headers = apiHeaders();
    try {
        const response = await fetch(axiosURL(action), {
            method: 'POST',
            headers,
            body: JSON.stringify({ ...data, stage }),
        });
        return await respunse(response);
    } catch (error) {
        return { status: 606, message: 'Network request failed', data: error };
    }
}

export const put = async (action: string, data = {}) => {
    const headers = apiHeaders();
    try {
        const response = await fetch(axiosURL(action), {
            method: 'PUT',
            headers,
            body: JSON.stringify(data),
        });
        return await respunse(response);
    } catch (error) {
        return { status: 606, message: 'Network request failed', data: error };
    }
}

export const patch = async (action: string, data = {}) => {
    const headers = apiHeaders();
    try {
        const response = await fetch(axiosURL(action), {
            method: 'PATCH',
            headers,
            body: JSON.stringify(data),
        });
        return await respunse(response);
    } catch (error) {
        return { status: 606, message: 'Network request failed', data: error };
    }
}

export const delte = async (action: string, data: any = {}) => {
    const headers = apiHeaders();
    try {
        const response = await fetch(axiosURL(action), {
            method: 'DELETE',
            headers,
            body: JSON.stringify(data),
        });
        return await respunse(response);
    } catch (error) {
        return { status: 606, message: 'Network request failed', data: error };
    }
}

export const postFile = async (action: string, data: any = {}) => {
    const headers = apiHeaders('file');
    try {
        const response = await fetch(axiosURL(action), {
            method: 'POST',
            headers,
            body: apnData(data),
        });
        return await respunse(response);
    } catch (error) {
        return { status: 606, message: 'Network request failed', data: error };
    }
}

export const getDev = async (action: string, data: any = {}) => {
    const headers = apiHeaders('dev');
    try {
        const response = await fetch(axiosURL(action) + '?' + new URLSearchParams(data).toString(), {
            method: 'GET',
            headers,
        });
        return await respunse(response);
    } catch (error) {
        return { status: 606, message: 'Network request failed', data: error };
    }
}

export const postDev = async (action: string, data: any = {}) => {
    const headers = apiHeaders('dev');
    try {
        const response = await fetch(axiosURL(action), {
            method: 'POST',
            headers,
            body: JSON.stringify(data),
        });
        return await respunse(response);
    } catch (error) {
        return { status: 606, message: 'Network request failed', data: error };
    }
}

const respunse = async (response: Response) => {
    const res = await response.json();
    const token = helpers.isJson(helpers.getStorageJson('auth').token) ? JSON.parse(helpers.getStorageJson('auth').token) : '';
    const status = response.status;
    if (status === 401 && token) {
        signOutSuccess('/auth/login');
    }
    const message = Array.isArray(res) && ![201, 200].includes(status) ? res.join("\n\n") : (res.message || '');
    return { data: res, status, message };
}