import React from 'react';
import { Loading } from 'components';
import { helpers } from 'helpers';
import { Route, Routes } from 'react-router-dom';

const PayLinks = React.lazy(() => import('./PayLinks'));
const PayLinksDetails = React.lazy(() => import('./PayLinksDetails'));

const LinksIndex = () => {

    const routes = [
        { path: '', element: <PayLinks />, role: 'LINKS', },
        { path: ':id', element: <PayLinksDetails />, role: 'LINKS_EDIT', },
    ].filter(item => !item.role || helpers.hasPermission(item.role));

    return (
        <React.Fragment>
            <React.Suspense fallback={<Loading spinning={true} />}>
                <Routes>
                    {routes.map(route => (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={route.element}
                        />
                    ))}
                </Routes>
            </React.Suspense>
        </React.Fragment>
    );
}

export default LinksIndex;