import React from 'react';

interface Props {
    type: 'card' | 'line';
    items: {
        key: string;
        label: any;
        children: any;
    }[];
    value: string;
    onChange: (e: string) => void;
}
const Tabs = ({ type, items, value, onChange }: Props) => {

    const active = items.find(item => item.key === value);

    return (
        <React.Fragment>
            <div id="Tabs" className={`${type}`}>
                {items.map(item => (
                    <div
                        key={item.key}
                        className={`tab px-4 py-3 cursor-pointer ${value === item.key ? 'active font-semibold' : ''} bg-white hover:bg-gray-100`}
                        onClick={() => onChange(item.key)}
                    >
                        {item.label}
                    </div>
                ))}
            </div>
            {active?.children}
        </React.Fragment>
    );

};

export default Tabs;