import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from 'helpers';
import { RootState } from '../reducer';


export const utilsApi = createApi({
  reducerPath: 'utilsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.server,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState)._auth.token;
      if (token) {
        headers.set('Authorization', `${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    config: build.query({
      query: () => ({
        url: `config`,
        method: 'GET',
      }),
    }),
    configuration: build.query({
      query: () => ({
        url: `configuration`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLazyConfigQuery,
  useLazyConfigurationQuery,
} = utilsApi;
