import React from 'react';
import { helpers, types } from 'helpers';
import { Balances, Loading } from 'components';
import { useTypedSelector } from 'Store/_Store';

const DashChart = React.lazy(() => import('./Chart'));
const DashTransactions = React.lazy(() => import('./Transactions'));

const Dashboard = () => {

    const { _auth: { business }, } = useTypedSelector((state) => state);

    const [currency, setCurrency] = React.useState<types.Currencies | null>(null);
    const [overview, setOverview] = React.useState<types.TrxOverview>({
        performance: {},
        totalAmount: 0,
        totalsDebit: {},
        totalsCredit: {},
    });

    React.useEffect(() => {
        // setCurrency((business.wallets || [])[0]?.currency);
        // eslint-disable-next-line 
    }, []);

    return (
        <React.Fragment>
            <React.Suspense fallback={<Loading spinning={true} />}>
                <Balances onChange={setCurrency} />
                {currency && business?.wallets?.length === 1 && (
                    <div className="grid grid-cols-12 gap-3 mb-4">
                        <div className="col-span-12 md:col-span-3 lg:col-span-3 bg-white p-6 rounded-xl border_ shadow-sm_ border-gray-200">
                            <div className="text-gray-400 font-light mb-2 text-sm">Total volume</div>
                            <div className="font-semibold text-2xl">
                                {helpers.format.currency(overview.totalAmount)}
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-5 lg:col-span-5 bg-white p-6 rounded-xl border_ shadow-sm_ border-gray-200">
                            <div className="grid grid-cols-2 gap-10">
                                <div className="col-span-1 border-r border-r-gray-200">
                                    <div className="text-gray-400 font-light mb-2 text-sm">Total collections</div>
                                    <div className="font-semibold text-2xl">
                                        {helpers.format.currency(
                                            (Object.values(overview.totalsDebit) as number[] || [])
                                                .reduce((accumulator: number, amount) => accumulator + amount, 0)
                                        )}
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <div className="text-gray-400 font-light mb-2 text-sm">Total payouts</div>
                                    <div className="font-semibold text-2xl">
                                        {helpers.format.currency(
                                            (Object.values(overview.totalsCredit) as number[] || [])
                                                .reduce((accumulator: number, amount) => accumulator + amount, 0)
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <DashChart currency={currency || (business.wallets || [])[0]?.currency} onChange={setOverview} />
                <DashTransactions currency={currency} />
            </React.Suspense>
        </React.Fragment>
    );
}

export default Dashboard;