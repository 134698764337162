import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { _logout } from 'Store/slices/auth.slice';

export const unauthenticatedMiddleware: Middleware = ({ dispatch }) =>
  (next) => (action) => {
    if (isRejectedWithValue(action) && (action.payload as any).status === 401) {
      notification.error({ message: 'Unauthorized' });
      dispatch(_logout());
    }

    return next(action);
  };
