/* eslint-disable @typescript-eslint/no-empty-function */
import { helpers } from 'helpers';
import React from 'react';


interface Props {
    step: number;
    steps: number;
    setStep: any;
}
const Steps = (props: Props) => {

    const { step, steps, setStep } = props;

    const foo = [];
    for (let i = 1; i <= steps; i++) {
        foo.push(i);
    }

    return (
        <React.Fragment>
            <div id="Steps" className="">
                <div>
                    <span className="font-semibold">Step {step}</span> <span className="text-gray-500">of {helpers.format.number(steps, 0)}</span>
                </div>
                <div className="mt-2 flex justify-end">
                    {foo.map(bubble => (
                        <div
                            key={bubble}
                            className={`
                                bubble
                                ${step > 1 && bubble < steps ? 'fill' : ''}
                                ${step === bubble ? 'long' : ''}
                            `}
                            onClick={() => setStep(bubble)}
                        />
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
}

export default Steps;