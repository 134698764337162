import React from 'react';
import { Form, Input, Select } from 'antd';
import { helpers, types } from 'helpers';


interface Props {
    name?: string[];
    label?: string;
    required?: boolean;
    submitting: boolean;
    currencies: types.Currencies[];
    placeholder?: string;
}
const AmountCurrency = ({ name, label, required, submitting, currencies, placeholder }: Props) => {

    return (
        <React.Fragment>
            <div id="AmountCurrency" className={``}>
                <label htmlFor="AmountCurrencyLabel" className="font-medium block mb-2 text-gray-700">{label || 'Amount'}</label>
                <div className="flex">
                    <div className="w-3/12">
                        <Form.Item
                            name={name ? name[0] : 'currency'}
                            rules={required ? helpers.requiredInput : []}
                            className="mb-0"
                            // initialValue={currencies[0]}
                        >
                            <Select
                                size="large"
                                placeholder="Select currency"
                                disabled={currencies.length === 1 || submitting}
                            >
                                {currencies.map(option => (
                                    <Select.Option key={option} value={option}>
                                        {option}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="w-9/12">
                        <Form.Item
                            name={name ? name[1] : 'amount'}
                            rules={required ? helpers.requiredInput : []}
                            className="mb-0"
                        >
                            <Input
                                id="AmountCurrencyLabel"
                                size="large" type="number" disabled={submitting}
                                placeholder={placeholder || 'How much should they pay?'}
                            />
                        </Form.Item>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );

};

export default AmountCurrency;