import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Loading, Tabs } from 'components';
import { config, helpers, types } from 'helpers';
import { useTypedSelector } from 'Store/_Store';

const SettingsKeys = React.lazy(() => import('./Keys'));
const SettingsTeam = React.lazy(() => import('./Team'));
const SettingsRoles = React.lazy(() => import('./Roles'));
const SettingsProfile = React.lazy(() => import('./Profile'));
const SettingsPassword = React.lazy(() => import('./Password'));
const SettingsBusiness = React.lazy(() => import('./Business'));
const SettlementAccounts = React.lazy(() => import('./SettlementAccounts'));
const SettingsVirtualAccounts = React.lazy(() => import('./VirtualAccounts'));

const Settings = () => {

    const { _auth: { business }, _utils: { env } } = useTypedSelector((state) => state);

    const navigate = useNavigate();
    const location = useLocation();
    const current = location.pathname.split('/')[2] || 'profile';

    const items = [
        { key: 'profile', label: 'Profile', children: <SettingsProfile />, },
        { key: 'password', label: 'Password', children: <SettingsPassword />, },
        { key: 'business', label: 'Business', children: <SettingsBusiness />, role: 'SETTINGS_BUSINESS', },
        { key: 'team', label: 'Team', children: <SettingsTeam />, role: 'SETTINGS_TEAM', },
        { key: 'permissions', label: 'Permissions', children: <SettingsRoles />, role: 'SETTINGS_PERMISSIONS', },
        { key: 'keys', label: 'API Keys', children: <SettingsKeys />, role: 'SETTINGS_API', },
        { key: 'settlement-accounts', label: 'Settlement Accounts', children: <SettlementAccounts />, role: 'SETTINGS_SETTLEMENT', },
    ].filter(item => !item.role || helpers.hasPermission(item.role));

    if ((env === 'live' || config.env !== 'production') && helpers.businessHasAccess(business, types.Currencies.NGN) && helpers.hasPermission('SETTINGS_VIRTUAL')) {
        items.push({
            key: 'virtual-accounts', label: 'Virtual accounts', children: <SettingsVirtualAccounts type="virtual" />,
        });
    }

    if ((env === 'live' || config.env !== 'production') && (helpers.businessHasAccess(business, types.Currencies.USDT) && helpers.hasPermission('SETTINGS_CRYPTO'))) {
        items.push({
            key: 'wallets', label: 'Crypto wallets', children: <SettingsVirtualAccounts type="crypto" />,
        });
    }

    return (
        <React.Fragment>
            <React.Suspense fallback={<Loading spinning={true} />}>
                <Tabs
                    type="card"
                    value={current}
                    items={items}
                    onChange={(e) => navigate(e)}
                />
            </React.Suspense>
        </React.Fragment>
    );
}

export default Settings;