import React from 'react';
import { Progress } from 'antd';
import { BusinessCenter } from '@mui/icons-material';
import { useTypedSelector } from 'Store/_Store';
import { useLocation, useNavigate } from 'react-router-dom';
import { helpers, types } from 'helpers';
import Status from './Status';

const ProgressKYB = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { _auth: { business }, _utils: { env } } = useTypedSelector((state) => state);
    const rejection = business.compliance?.rejections && business.compliance?.rejections[Object.keys(business.compliance?.rejections)[0]];

    return (
        <React.Fragment>
            {business.status !== types.TeamStatus.ACCEPTED && env === 'live' && (
                <div className="bg-white flex justify-between items-center p-4 rounded-xl">
                    <div className="flex gap-4 w-6/12">
                        <div className="material-symbols-outlined-- flex justify-center items-center h-[34px] w-[34px] bg-[#faf3e0] text-[#FFC940] rounded-full">
                            <BusinessCenter />
                        </div>
                        <div className="w-full">
                            <div className="flex gap-4">
                                <div className="font-semibold">Complete KYB submission</div>
                                {rejection && business.status === types.TeamStatus.REJECTED && (
                                    <Status status="REJECTED" label={rejection} />
                                )}
                            </div>
                            <div className="w-6/12">
                                <Progress percent={helpers.KYBCompletion(business).all} strokeColor="#FFC940" trailColor="#EAECF0" />
                            </div>
                        </div>
                    </div>
                    <div>
                        {location.pathname !== '/settings/business' && helpers.KYBCompletion(business).all < 100 && (
                            <div
                                className="px-5 py-3 cursor-pointer border border-[#00A6A6] text-[#00A6A6] hover:border-[#027a7a] hover:text-[#027a7a] transi rounded-xl"
                                onClick={() => navigate('/settings/business')}
                            >
                                Resume onboarding
                            </div>
                        )}
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default ProgressKYB;