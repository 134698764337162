import React from 'react';
import { Button, Checkbox, Drawer, Form, Input, notification, Select } from 'antd';
import { axius, helpers, images, types } from 'helpers';
import { useTypedSelector } from 'Store/_Store';
import { _setUtils } from 'Store/slices/utils.slice';
import { useDispatch } from 'react-redux';

interface Props {
    row?: types.SettlementAccount;
    open: boolean;
    onClose: () => void;
    onSuccess?: (e: types.SettlementAccount) => void;
}
const FormSettlementAccount = ({ open, row, onClose, onSuccess }: Props) => {

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const { _utils: { config, configuration }, _auth: { business } } = useTypedSelector((state) => state);

    const [vv, setValues] = React.useState({ channel: '', currency: '', });
    const [isLoading, setSubmitting] = React.useState(false);

    const crc = form.getFieldValue('currency');

    const findChannels = (currency: string) => {
        return (configuration.channels_by_currency[`${currency}${types.TrxTypes.SETTLEMENT}`] || [])
            .map((channel: types.TrxChannels) => {
                return {
                    value: channel,
                    label: types.TrxChannelsLabels[channel],
                };
            })
    }

    const channels = findChannels(vv.currency);
    const wallets = Object.keys(types.Currencies).map(tc => {
        return {
            value: tc,
            label: types.CurrenciesLabels[tc as unknown as types.Currencies],
        };
    });
    const networks = (config.banks[crc as keyof typeof config.banks] || []).filter(o => o.channel === form.getFieldValue('channel') && o.types.includes(types.TrxTypes.SETTLEMENT));

    React.useEffect(() => {
        if (open) {
            const initialValues = {
                channel: channels[0]?.value,
                network: null,
                currency: vv.currency || wallets[0].value,
                accountName: null,
                accountNumber: null,
            };
            setValues(initialValues);
            form.setFieldsValue(initialValues);
        }
        // eslint-disable-next-line
    }, [open, crc]);

    const submit = async (v: any) => {
        setSubmitting(true);
        axius[row?.id ? 'put' : 'post'](`business/${business.id}/settlement-accounts`, {
            ...v,
            id: row?.id,
            details: { ...row?.details, ...v.details, },
            network: v.network || row?.network,
            channel: v.channel || row?.channel,
            currency: v.currency || row?.currency,
            accountName: v.accountName || row?.account_name,
            accountNumber: v.accountNumber || row?.account_number,
        }).then(res => {
            if ([200, 201].includes(res.status)) {
                if (row?.id) {
                    notification.success({
                        message: `Settlement account edit request submitted`,
                    });
                } else {
                    notification.success({
                        message: `Settlement account successfully added!`,
                        description: `Start transferring funds today.`,
                    });
                }
                dispatch(_setUtils({ key: 'businessCache', value: res.data.id }));
                onSuccess && onSuccess(res.data);
                close();
            } else {
                notification.error({ ...res, });
            }
            setSubmitting(false);
        });
    }

    const close = () => {
        onClose();
        form.resetFields();
    }

    const numberLabels = {
        [types.TrxChannels.BANK]: 'Account number',
        [types.TrxChannels.MOMO]: 'Mobile number',
        [types.TrxChannels.CARD]: 'Card number',
        [types.TrxChannels.CRYPTO]: 'Address',
    };

    return (
        <React.Fragment>
            <Drawer
                open={open}
                title={null}
                width={480}
                closable={true}
                onClose={close}
                maskClosable={false}
            >
                <Form
                    form={form} layout="vertical" autoComplete="off" onFinish={submit}
                    onFieldsChange={(e: any) => {
                        setValues((sv) => ({ ...sv, [e[0].name[0]]: e[0].value }));
                        if (e[0].name[0] === 'network') {
                            form.setFieldValue('accountNumber', null);
                        }
                    }}
                >
                    <div className="flex justify-between items-center mb-8">
                        <div className="text-xl font-semibold">
                            {row?.id ? 'Settlement account edit request' : 'Add settlement account'}
                        </div>
                        <div
                            className="bg-gray-100 rounded-lg cursor-pointer hover:bg-gray-200 p-3 ml-2"
                            onClick={close}
                        >
                            <img src={images.Close} alt="Close" />
                        </div>
                    </div>

                    {row?.id ? (
                        <div>
                            <Form.Item name={['details', 'edit_reason']} label="Reason for edit request" rules={helpers.requiredInput} className="mb-2">
                                <Input.TextArea size="large" rows={5} autoComplete="off" placeholder="Please tell us your reason..." disabled={isLoading} />
                            </Form.Item>
                            <div className="mt-8">
                                <Button type="primary" size="large" htmlType="submit" block loading={isLoading}>
                                    Submit
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className="grid grid-cols-12 gap-4">
                            <div className="col-span-7">
                                <Form.Item name="currency" label="Currency" rules={helpers.requiredInput} className="mb-2">
                                    <Select
                                        size="large"
                                        placeholder="Select currency"
                                        disabled={isLoading}
                                    >
                                        {wallets.map(option => (
                                            <Select.Option key={option.value} value={option.value}>
                                                ({option.value}) - {option.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-span-5">
                                <Form.Item name="channel" label="Channel" rules={helpers.requiredInput} className="mb-2">
                                    <Select
                                        size="large"
                                        placeholder="Select channel"
                                        showSearch optionFilterProp="children"
                                        disabled={channels.length === 1 || isLoading}
                                        onChange={() => form.setFieldValue('network', null)}
                                    >
                                        {channels.map(option => (
                                            <Select.Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-span-12">
                                <Form.Item name="accountName" label="Account name" rules={helpers.requiredInput} className="mb-2">
                                    <Input size="large" placeholder="John Doe" disabled={isLoading} />
                                </Form.Item>
                            </div>
                            <div className="col-span-12">
                                <Form.Item
                                    name="network"
                                    label={form.getFieldValue('channel') === types.TrxChannels.BANK ? 'Bank' : 'Network'}
                                    rules={helpers.requiredInput} className="mb-2"
                                >
                                    {networks.length > 0 ? (
                                        <Select
                                            size="large"
                                            placeholder="Select network"
                                            showSearch optionFilterProp="children"
                                            disabled={isLoading}
                                        >
                                            {networks.map(option => (
                                                <Select.Option key={option.name} value={option.name}>
                                                    {option.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    ) : (
                                        <Input size="large" type="text" autoComplete="off" placeholder="Bank name" disabled={isLoading} />
                                    )}
                                </Form.Item>
                            </div>
                            <div className="col-span-12">
                                <Form.Item
                                    name="accountNumber"
                                    label={numberLabels[form.getFieldValue('channel') as types.TrxChannels]}
                                    rules={helpers.requiredInput} className="mb-2"
                                >
                                    <Input size="large" type="text" autoComplete="off" placeholder="020 000 0000" disabled={isLoading} />
                                </Form.Item>
                            </div>
                            {vv.currency === types.Currencies.GHS && form.getFieldValue('channel') === types.TrxChannels.BANK && (
                                <div className="col-span-12">
                                    <Form.Item name={['details', 'branch_name']} label="Branch Name" rules={helpers.requiredInput} className="mb-2">
                                        <Input size="large" type="text" autoComplete="off" placeholder="East Legon" disabled={isLoading} />
                                    </Form.Item>
                                </div>
                            )}
                            {[types.Currencies.USD, types.Currencies.EUR, types.Currencies.GBP].includes(crc) && (
                                <>
                                    <div className="col-span-12 border-b my-6" />
                                    {[
                                        'iban', 'sort_code', 'bic', 'country', 'bank_address', 'beneficiary_name',
                                        'beneficiary_address', 'industry', 'year_of_registration', 'website',
                                    ].map(input => (
                                        <div key={input} className={`col-span-${['iban', 'sort_code', 'bic', 'country',].includes(input) ? 6 : 12}`}>
                                            <Form.Item
                                                name={['details', input]} label={helpers.ucFirst(input.split('_').join(' '))}
                                                rules={['iban', 'sort_code', 'bic'].includes(input) ? helpers.requiredInput : undefined}
                                                className="mb-2"
                                            >
                                                <Input size="large" type="text" autoComplete="off" placeholder="" disabled={isLoading} />
                                            </Form.Item>
                                        </div>
                                    ))}
                                </>
                            )}
                            {!row?.id && (
                                <div className="col-span-12">
                                    <Form.Item name="is_primary" valuePropName="checked" className="mb-2">
                                        <Checkbox disabled={isLoading}>
                                            Make this my primary settlement account
                                        </Checkbox>
                                    </Form.Item>
                                </div>
                            )}
                            <div className="col-span-12 mt-4">
                                <Button type="primary" size="large" htmlType="submit" block loading={isLoading}>
                                    Submit
                                </Button>
                            </div>
                        </div>
                    )}
                </Form>
            </Drawer>
        </React.Fragment>
    );
};

export default FormSettlementAccount;