import React from 'react';

// ::: app pages
import PayLinks from 'pages/PayLinks/_PayLinks';
import Settings from 'pages/Settings/_Settings';
import Transfers from 'pages/Transactions/Transfers';
import Dashboard from 'pages/Dashboard/_Dashboard';
import Settlements from 'pages/Transactions/Settlements';
import Transactions from 'pages/Transactions/Transactions';

// ::: auth pages
const Auth = React.lazy(() => import('pages/Auth/_Auth'));


const routes = [
    { path: `/auth/*`, element: Auth, auth: false, },

    { path: `/`, element: Dashboard, auth: true, },
    { path: `/collections`, element: Transactions, auth: true, },
    { path: `/payouts`, element: Transfers, auth: true, },
    { path: `/settlements`, element: Settlements, auth: true, },
    { path: `/settings/*`, element: Settings, auth: true, },
    { path: `/paylinks/*`, element: PayLinks, auth: true, },
    { path: `*`, element: Dashboard, auth: true, },
];

export default routes;