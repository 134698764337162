import React from 'react';
import { Button, Checkbox, Drawer, Form, Input, notification, Select } from 'antd';
import { images, types } from 'helpers';
import { useAddBusinessMutation, useEditBusinessMutation } from 'Store/api/auth.api';
import { useDispatch } from 'react-redux';
import { _setUtils } from 'Store/slices/utils.slice';

interface Props {
    row?: types.Business;
    open: boolean;
    onClose: () => void;
    onSuccess?: (e: types.Business) => void;
}
const FormBusiness = ({ open, row, onClose, onSuccess }: Props) => {

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const [addBusiness, { isLoading: isAbLoading, isError: isAbError, isSuccess: isAbSuccess, error: abError, data: abData }] = useAddBusinessMutation();
    const [editBusiness, { isLoading: isEbLoading, isError: isEbError, isSuccess: isEbSuccess, error: ebError, data: ebData }] = useEditBusinessMutation();

    const data = abData || ebData;
    const error = abError || ebError;
    const isError = isAbError || isEbError;
    const isLoading = isAbLoading || isEbLoading;
    const isSuccess = isAbSuccess || isEbSuccess;

    React.useEffect(() => {
        if (row?.id && open) {
            form.setFieldsValue(row);
        } else {
            form.resetFields();
        }
        // eslint-disable-next-line
    }, [row, open]);

    React.useEffect(() => {
        isError && notification.error({ message: (error as any).data?.message || ((error as any).data || []).join(', ') });
        if (isSuccess) {
            notification.success({ message: 'Business changes saved' });
            dispatch(_setUtils({ key: 'meCache', value: data?.updated_at }));
            onSuccess && onSuccess(data as types.Business);
            onClose();
        }
        // eslint-disable-next-line
    }, [isError, isSuccess]);

    const submit = async (v: Partial<types.Business>) => {
        v['id'] = row?.id;
        v['callback_url'] = row?.callback_url || 'https://google.com';
        if (row?.id) {
            await editBusiness(v);
        } else {
            await addBusiness(v);
        }
    }

    const close = () => {
        onClose();
        form.resetFields();
    }

    const rules = [{ required: true, message: 'This field is required' }];

    return (
        <React.Fragment>
            <Drawer
                open={open}
                title={null}
                width={480}
                closable={true}
                onClose={close}
                maskClosable={false}
            >
                <Form form={form} layout="vertical" onFinish={submit}>
                    <div className="flex justify-between items-center mb-8">
                        <div className="text-xl font-semibold">
                            {row?.id ? 'Edit business details' : 'Add new business'}
                        </div>
                        <div
                            className="bg-gray-100 rounded-lg cursor-pointer hover:bg-gray-200 p-3 ml-2"
                            onClick={close}
                        >
                            <img src={images.Close} alt="Close" />
                        </div>
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                        <div className="col-span-2">
                            <Form.Item name="name" label="Business name" rules={rules}>
                                <Input size="large" type="text" placeholder="Company name" autoComplete="off" disabled={isLoading} />
                            </Form.Item>
                        </div>
                        <div className="col-span-2">
                            <Form.Item name="description" label="Describe your business" rules={rules}>
                                <Input.TextArea size="large" rows={4} placeholder="Start typing..." autoComplete="off" disabled={isLoading} />
                            </Form.Item>
                        </div>
                        <div className="col-span-1">
                            <Form.Item name="industry" label="Industry" className="mb-2" rules={rules}>
                                <Select size="large" showSearch optionFilterProp="children" placeholder="Select industry" disabled={isLoading}>
                                    {Object.keys(types.BusinessIndustries).map(option => (
                                        <Select.Option key={option} value={option}>
                                            {types.BusinessIndustries[option as keyof typeof types.BusinessIndustries]}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-span-1">
                            <Form.Item name="team_size" label="Team size" className="mb-2" rules={rules}>
                                <Select size="large" placeholder="Select team size" disabled={isLoading}>
                                    {Object.keys(types.BusinessTeamSizes).map(option => (
                                        <Select.Option key={option} value={option}>
                                            {types.BusinessTeamSizes[option as keyof typeof types.BusinessTeamSizes]}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                        {!row?.id && (
                            <div className="col-span-2">
                                <Form.Item name="agreement" valuePropName="checked" rules={rules}>
                                    <Checkbox disabled={isLoading}>
                                        You agree to our privacy policy.
                                    </Checkbox>
                                </Form.Item>
                            </div>
                        )}
                        <div className="col-span-2 mt-8">
                            <Button type="primary" size="large" htmlType="submit" block loading={isLoading}>
                                Submit
                            </Button>
                        </div>
                    </div>
                </Form>
            </Drawer>
        </React.Fragment>
    );
};

export default FormBusiness;