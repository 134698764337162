import React from 'react';
import { useTypedDispatch, useTypedSelector } from 'Store/_Store';
import { FormBusiness } from 'components';
import { useLocation, useNavigate } from 'react-router-dom';
import { _setUtils } from 'Store/slices/utils.slice';

interface Props {
    route: {
        auth: boolean;
        path: string;
        types?: string[];
        element: any;
    };
    children: any;
}
const Raute = ({ route, children }: Props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useTypedDispatch();

    const { _auth: { user, token }, _utils: { addBusiness } } = useTypedSelector((state) => state);

    const isLoggedIn = !!(user.id && token && !location.pathname.includes('auth'));
    const isAuthorized = route.auth === isLoggedIn;
    const hasBusiness = isLoggedIn && (user?.teams || []).length > 0;

    React.useEffect(() => {
        if (isLoggedIn) {
            location.pathname.includes('auth') && navigate('/', { replace: true });
        } else {
            !location.pathname.includes('auth') && navigate(`/auth/login`, { replace: true });
        }
        // eslint-disable-next-line 
    }, [route, isAuthorized]);

    return (
        <React.Fragment>
            {children}

            <FormBusiness
                open={isLoggedIn && (!hasBusiness || addBusiness)}
                onClose={() => {
                    if (hasBusiness) {
                        dispatch(_setUtils({ key: 'addBusiness', value: false }));
                    }
                }}
                onSuccess={() => {
                    dispatch(_setUtils({ key: 'addBusiness', value: false }));
                    window.location.reload();
                }}
            />

        </React.Fragment>
    );
};

export default Raute;