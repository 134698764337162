import React from 'react';
import { types } from 'helpers';

interface Props {
    label: string;
    status: string;
}
const Status = ({ label, status }: Props) => {
    const color = {
        [types.TeamStatus.PENDING]: 'warning',
        [types.TeamStatus.ACCEPTED]: 'success',
        [types.TeamStatus.REJECTED]: 'danger',

        [types.TrxStatus.OTP]: 'warning',
        [types.TrxStatus.INITIATED]: 'warning',
        [types.TrxStatus.FAILED]: 'danger',
        [types.TrxStatus.COMPLETED]: 'success',
    }
    const kolor = color[status as keyof typeof color];

    return (
        <React.Fragment>
            <div id="Status" className={`${kolor} rounded-full inline-flex items-center py-1 px-3 text-sm font-medium capitalize`}>
                <div className={`h-[6px] w-[6px] rounded-full mr-2 dot ${kolor}`} />
                {label}
            </div>
        </React.Fragment>
    );

};

export default Status;