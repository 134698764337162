import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authApi } from '../api/auth.api';
import { helpers, types } from 'helpers';

interface AuthState {
  user: types.User;
  token: string;
  business: types.Business;
}

const initialState: AuthState = {
  user: {} as types.User,
  token: '',
  business: {} as types.Business,
}

export const authSlice = createSlice({
  name: '_auth',
  initialState,
  reducers: {
    _logout: (state) => {
      state.user = {} as types.User;
      state.token = '';
      state.business = {} as types.Business;
    },
    _setBusiness: (state, action: PayloadAction<types.Business>) => {
      state.business = action.payload;
    },
  },
  extraReducers: (builder) => {
    // builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
    //   state.user = action.payload.data;
    //   state.business = action.payload.data.teams[0].business;
    // });

    builder.addMatcher(authApi.endpoints.loginOtp.matchFulfilled, (state, action) => {
      state.token = action.payload.token;
    });

    // builder.addMatcher(authApi.endpoints.signup.matchFulfilled, (state, action) => {
    //   state.user = action.payload.user;
    //   state.token = action.payload.token;
    //   state.business = action.payload.business;
    // });

    // builder.addMatcher(authApi.endpoints.acceptInvite.matchFulfilled, (state, action) => {
    //   state.user = action.payload.data;
    //   state.token = action.payload.token;
    //   state.business = action.payload.business;
    // });

    builder.addMatcher(authApi.endpoints.me.matchFulfilled, (state, action) => {
      state.user = action.payload;
    });

    builder.addMatcher(authApi.endpoints.editUser.matchFulfilled, (state, action) => {
      state.user = action.payload;
    });

    builder.addMatcher(authApi.endpoints.business.matchFulfilled, (state, action) => {
      const business: types.Business = helpers.copyObject(action.payload);
      business['preferences']['bear_fee'] = {
        payout: business.preferences.bear_fee?.payout || 'business',
        collection: business.preferences.bear_fee?.collection || 'business',
      };
      state.business = business;
    });

    builder.addMatcher(authApi.endpoints.editBusiness.matchFulfilled, (state, action) => {
      if (state.business.id === action.payload.id) {
        state.business = action.payload;
      }
    });

    builder.addMatcher(authApi.endpoints.addBusiness.matchFulfilled, (state, action) => {
      state.business = action.payload;
    });
  },
});

export const { _logout, _setBusiness } = authSlice.actions;