import React from 'react';
import { types } from 'helpers';
import { Loading } from 'components';

const List = React.lazy(() => import('./_Transactions'));

const Transactions = () => {

    return (
        <React.Fragment>
            <React.Suspense fallback={<Loading spinning={true} />}>
                <List type={types.TrxTypes.DEBIT} />
            </React.Suspense>
        </React.Fragment>
    );
}

export default Transactions;