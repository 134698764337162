import React from 'react';
import { axius, helpers, images, types } from 'helpers';
import { notification, Spin } from 'antd';
import { LoadingMin } from './Loading';

interface Props {
    row?: { name: string; link: string; };
    clear?: boolean;
    label?: string;
    accept?: string;
    folder: types.Folders;
    preview?: 'text' | 'image';
    className?: string;
    helperText?: any;
    helperDescription?: any;

    onSuccess?: (e: { name: string; link: string; }) => void;
}
const Upload = (props: Props) => {
    const inputRef = React.useRef<HTMLInputElement>(null);

    const { row, clear, label, accept, folder, className, helperText, helperDescription, onSuccess } = props;
    const preview = props.preview || 'image';

    const [name, setName] = React.useState(null);
    const [link, setLink] = React.useState(null);
    const [file, setFile] = React.useState(null);
    const [image, setImage] = React.useState(null);
    const [upload, setUpload] = React.useState(false);
    const [uploading, setUploading] = React.useState(false);

    React.useEffect(() => {
        file && upload && uploadFile();
        // eslint-disable-next-line
    }, [file, upload]);

    React.useEffect(() => {
        clear && clearData();
        // eslint-disable-next-line
    }, [clear]);

    React.useEffect(() => {
        if (row?.name) {
            setName(row.name as any);
            setLink(row.link as any);
            setImage(row.link as any);
        }
        // eslint-disable-next-line
    }, [row]);

    const changeFile = () => {
        const fale = (inputRef.current?.files || [])[0];
        if (fale) {
            const reader = new FileReader();
            reader.onload = () => {
                setFile(fale as any);
                setImage(reader.result as any);
                setUpload(true);
            }
            reader.readAsDataURL(fale);
        }
    }

    const uploadFile = () => {
        setUploading(true);
        axius.postFile(`upload?folder=${folder}`, { file, folder }).then(res => {
            if (res.status === 201) {
                setName(res.data.name);
                setLink(res.data.link);
                onSuccess && onSuccess(res.data);
            } else {
                clearData();
                notification.error({ ...res });
            }
            setUpload(false);
            setUploading(false);
        });
    }

    const deleteFile = () => {
        setUploading(true);
        axius.delte(`upload/${folder}/${name}`).then(res => {
            if (res.status === 200) {
                clearData();
            }
            setUpload(false);
            setUploading(false);
        });
    }

    const clearData = () => {
        setName(null);
        setLink(null);
        setFile(null);
        setImage(null);
        onSuccess && onSuccess({ name: '', link: '' });
        // inputRef.current?.value = null;
    }

    return (
        <React.Fragment>
            <div
                id="Upload"
                className={`border border-gray-200 rounded-[8px] ${className}`}
            >
                <input
                    ref={inputRef}
                    type="file"
                    accept={`${accept || 'image/*'}`}
                    onChange={changeFile}
                    style={{ display: 'none' }}
                />

                <Spin spinning={uploading} indicator={<div className="flex justify-center items-center"><LoadingMin /></div>}>
                    {(image || row.name) ? (
                        <>
                            {preview === 'image' && (
                                <div className="p-[12px] flex justify-between items-center">
                                    <div className="pr-4 w-8/12">
                                        {/* <img src={image} alt="imagex" className="rounded-lg" onError={helpers.imgError} /> */}
                                        <div className="bg-img rounded-lg" style={{ backgroundImage: `url(${image})`, width: '100%', }} />
                                    </div>
                                    <div className="w-4/12">
                                        <div className="mb-2">
                                            <div
                                                className="p-2 rounded-lg border block text-center cursor-pointer hover:bg-gray-100 transi text-sm"
                                                onClick={() => inputRef.current?.click()}
                                            >
                                                Replace
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                className="p-2 rounded-lg border block text-center cursor-pointer hover:bg-gray-100 text-red-600 transi text-sm"
                                                onClick={deleteFile}
                                            >
                                                Delete
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {preview === 'text' && name && (
                                <div className="p-4">
                                    {label && (<div className="mb-2 font-medium">{label}</div>)}
                                    <div className="mb-5 flex justify-between items-center">
                                        <div className="truncate w-6/12 text-sm text-gray-500">{name}</div>
                                        <div className="flex gap-4">
                                            <div className="font-medium cursor-pointer primary" onClick={() => helpers.openNewTab(link)}>View</div>
                                            <div className="font-medium cursor-pointer danger" onClick={deleteFile}>Delete</div>
                                        </div>
                                    </div>
                                    <div
                                        className="border border-dashed text-center p-2 text-gray-500 rounded-lg cursor-pointer transi hover:border-[#00A6A6] hover:text-[#00A6A6]"
                                        onClick={() => inputRef.current?.click()}
                                    >
                                        Replace file
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <div
                            onClick={() => inputRef.current?.click()}
                            className="rounded-[8px] text-center cursor-pointer hover:bg-gray-50 p-[12px]"
                        >
                            <img src={images.Upload} alt="Upload" className="inline-block mx-auto mb-2" />
                            <div className="mb-2">
                                {helperText || <><span className="primary font-medium">Click to upload</span> or drag and drop image</>}
                            </div>
                            <div className="text-xs text-[#475467]">
                                {helperDescription || (
                                    <>
                                        <div className="mb-2">Images help customers visually confirm payments.</div>
                                        <div className="mb-2s">(max. 800x400px)</div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </Spin>
            </div>
        </React.Fragment>
    );
}

export default Upload;