import React from 'react';
import { axius, helpers, images, types } from 'helpers';
import { Button, Drawer, Form, Input, notification, Select } from 'antd';
import { useTypedSelector } from 'Store/_Store';
import Alert from './Alert';
import { _setUtils } from 'Store/slices/utils.slice';
import { useDispatch } from 'react-redux';


interface Props {
    open: boolean;
    onClose: () => void;
    onSuccess?: (e: types.SettlementAccount) => void;
}
const Converter = ({ open, onClose }: Props) => {

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const { _auth: { business }, _utils: { env } } = useTypedSelector((state) => state);

    const [v, setValues] = React.useState({ amount: 0, source: '', destination: '' });
    const [convert, setConvert] = React.useState({ loading: false, rate: 0, convertedAmount: 0 });
    const [submitting, setSubmitting] = React.useState(false);

    const prefs = business.preferences?.permissions || [];
    const stage = env === 'test' ? '_test' : '';
    const currencies = (business.wallets?.filter(w => +w[`balance${stage}`] > 0) || []).filter(w => prefs.includes(w.currency) && w.currency !== types.Currencies.USDT) as types.Wallet[];

    React.useEffect(() => {
        if (v.amount && v.source && v.destination) {
            const delayDebounceFn = setTimeout(() => {
                getRates();
            }, 2500);
            return () => clearTimeout(delayDebounceFn);
        }
        // eslint-disable-next-line
    }, [v]);

    const getRates = () => {
        const { amount, source, destination } = v;
        if (source && destination && source !== destination && amount > 0) {
            setConvert({ loading: true, rate: 0, convertedAmount: 0 });
            axius.getDev(`rates`, { source, destination, amount }).then(res => {
                setConvert({ loading: false, rate: res.data.rate, convertedAmount: res.data.convertedAmount });
                res.data.rate === 0 && notification.error({ message: `No pair for ${v.source} <> ${v.destination}` });
            });
        }
    }

    const submit = async (v: any) => {
        setSubmitting(true);
        v['stage'] = env;
        v['amount'] = +v.amount;
        axius.post(`business/${business.id}/convert`, v).then(res => {
            if ([200, 201].includes(res.status)) {
                notification.success({
                    message: `${helpers.format.currency(v.amount, v.source)} converted to ${helpers.format.currency(convert.convertedAmount, v.destination)}`,
                });
                dispatch(_setUtils({ key: 'businessCache', value: helpers.randCode(12) }));
                close();
            } else {
                notification.error({ ...res, });
            }
            setSubmitting(false);
        });
    }

    const close = () => {
        onClose();
        setValues({ amount: 0, source: '', destination: '' });
        setConvert({ loading: false, rate: 0, convertedAmount: 0 });
        form.resetFields();
    }

    return (
        <React.Fragment>
            <Drawer
                open={open}
                title={null}
                width={480}
                closable={true}
                onClose={close}
                maskClosable={false}
            >
                <Form form={form} layout="vertical" onFinish={submit}>
                    {currencies.length > 0 && (
                        <>
                            <div className="flex justify-between items-center mb-8">
                                <div className="text-xl font-semibold">Currency converter</div>
                                <div
                                    className="bg-gray-100 rounded-lg cursor-pointer hover:bg-gray-200 p-3 ml-2"
                                    onClick={close}
                                >
                                    <img src={images.Close} alt="Close" />
                                </div>
                            </div>

                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-6">
                                    <Form.Item name="source" label="From" rules={helpers.requiredInput} className="mb-0">
                                        <Select size="large" placeholder="From" showSearch optionFilterProp="children" disabled={submitting} onChange={source => setValues(sv => ({ ...sv, source }))}>
                                            {currencies.map(option => (
                                                <Select.Option key={option.currency} value={option.currency}>
                                                    {option.currency} {helpers.format.number(option[`balance${stage}`])}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-span-6">
                                    <Form.Item name="destination" label="To" rules={helpers.requiredInput} className="mb-0">
                                        <Select size="large" placeholder="To" showSearch optionFilterProp="children" disabled={submitting} onChange={destination => setValues(sv => ({ ...sv, destination }))}>
                                            {business.wallets?.map(w => w.currency).filter(c => c !== v.source).map((option) => (
                                                <Select.Option key={option} value={option}>
                                                    ({option}) {types.CurrenciesLabels[option]}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-span-12">
                                    <Form.Item name="amount" label="Amount" rules={helpers.requiredInput} className="mb-0">
                                        <Input
                                            size="large" type="number" autoComplete="off" disabled={submitting}
                                            suffix={(
                                                <div
                                                    className="cursor-pointer transi px-2 hover:bg-gray-100 rounded"
                                                    onClick={() => {
                                                        if (v.source) {
                                                            const wallet = business.wallets?.find(w => w.currency === v.source) as types.Wallet;
                                                            setValues(sv => ({ ...sv, amount: +wallet[`balance${stage}`] }));
                                                            form.setFieldValue('amount', +wallet[`balance${stage}`]);
                                                        }
                                                    }}>Max</div>
                                            )}
                                            onChange={e => {
                                                if (v.source) {
                                                    const amount = +e.target.value;
                                                    const wallet = business.wallets?.find(w => w.currency === v.source) as types.Wallet;
                                                    if (+wallet[`balance${stage}`] >= amount) {
                                                        setValues(sv => ({ ...sv, amount }));
                                                    } else {
                                                        e.preventDefault();
                                                        setValues(sv => ({ ...sv, amount: +wallet[`balance${stage}`] }));
                                                        form.setFieldValue('amount', +wallet[`balance${stage}`]);
                                                    }
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                </div>

                                {convert.rate > 0 && (
                                    <div className="col-span-12 mt-8">
                                        <Alert color="success" icon={false}>
                                            <div className="text-black grid grid-cols-2 garp-4">
                                                <div>
                                                    <div className="text-gray-500 text-xs mb-1">Rate</div>
                                                    <div>1 {v.source} ≈ {helpers.format.currency(convert.rate, v.destination)}</div>
                                                </div>
                                                <div>
                                                    <div className="text-gray-500 text-xs mb-1">Expected amount</div>
                                                    <div className="text-xl"><b>{helpers.format.currency(convert.convertedAmount, v.destination)}</b></div>
                                                </div>
                                            </div>
                                        </Alert>
                                    </div>
                                )}

                                <div className="col-span-12 mt-12">
                                    <Button type="primary" size="large" htmlType="submit" block disabled={!convert.convertedAmount} loading={convert.loading || submitting}>
                                        Initiate conversion
                                    </Button>
                                </div>
                            </div>
                        </>
                    )}
                </Form>
            </Drawer>
        </React.Fragment>
    );
};

export default Converter;