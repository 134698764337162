import { notification } from 'antd';
import { types } from 'helpers';
import moment from 'moment';
import { parsePhoneNumber } from 'awesome-phonenumber';
import * as rdc from 'react-device-detect';


export const config = {
    env: 'local',
    dbpref: 'persist:',
    version: require('../../package.json').version,
    local: {
        url: 'http://localhost:3001/',
    },
    dev: {
        url: 'https://stage-api.ogateway.io/',
    },
    prod: {
        url: 'https://db.ogateway.io/',
    },
};

export const app = {
    version: require('../../package.json').version,
}

export const initialize = () => {
    if (window.location.hostname === 'localhost') {
        config.env = 'local';
    } else if (window.location.hostname.includes('stage')) {
        config.env = 'dev';
    } else {
        config.env = 'prod';
    }
}


export const isJson = (str: any) => {
    try {
        JSON.parse(str);
        return true;
    } catch (error) {
        return false;
    }
}


// ::: storage
export const setStorage = (key: string, value: string) => {
    if (key && value) {
        localStorage.setItem(config.dbpref + key, value);
    }
}
export const getStorage = (key: string) => {
    const value = localStorage.getItem(config.dbpref + key);
    return value || '';
}
export const setStorageJson = (key: string, value: any) => {
    if (key && value) {
        localStorage.setItem(config.dbpref + key, JSON.stringify(value));
    }
}
export const getStorageJson = (key: string) => {
    if (key) {
        const value = localStorage.getItem(config.dbpref + key) || '';
        return isJson(value) ? JSON.parse(value) : '';
    }
}
export const delStorage = (key: string) => {
    if (key) {
        localStorage.removeItem(config.dbpref + key);
    }
}

export const redirect = (to: any) => {
    window.location = to;
}

export const openNewTab = (to: any) => {
    window.open(to, '_blank');
}

export const generateOptions = (length: number, step = 1) => {
    const arr = [];
    for (let value = 0; value < length; value += step) {
        arr.push(value);
    }
    return arr;
};

export const format = {
    date: (date: string) => isToday(date) ? `Today @ ${moment(date).format('H:mm A')}` : moment(date).format('MMM DD, YYYY HH:mm:ss'),
    number: (number: number, decimal = 3) => {
        number = number || 0;
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: decimal === 0 ? 0 : 2, maximumFractionDigits: decimal }).format(number);
    },
    currency: (amount: number | string, currency: string = types.Currencies.GHS) => {
        return `${types.CurrenciesSymbols[currency as types.Currencies]} ${format.number(+amount)}`;
    },
    phoneNumber: (msisdn: string) => {
        if (msisdn) {
            const regionCode = msisdn.includes('+') ? undefined : 'GH';
            const parsed = parsePhoneNumber(msisdn, { regionCode });
            return parsed.valid ? parsed.number.international : (msisdn.length > 20 ? msisdn : msisdn.match(/.{1,3}/g)?.join(' '));
        }
        return '';
    },
}


export const randNum = (length = 6) => {
    let result = '';
    const characters = '0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const randNumRange = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
}

export const randCode = (length = 6) => {
    let result = '';
    const characters = 'ABCDEFGHJKMNOPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const imgError = (e: any) => {
    e.target.src = '/download.svg';
}

export const objectToArray = (obj: any) => {
    let data = [];
    if (Array.isArray(obj)) {
        data = obj;
    } else {
        for (let i = 0; i < Object.keys(obj).length; i++) {
            data.push(obj[i]);
        }
    }
    return data;
}

export const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then((_) => {
        notification.success({ message: `Copied` });
    });
}

export const md5 = (str: any) => {
    return btoa(JSON.stringify(str));
}

export const isToday = (date: string) => !!(moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'));

export const daysBetweenDates = (startDate: any, endDate: any, duration: 'days' | 'months' = 'days') => {
    const dates = [];
    const format = { days: 'YYYY-MM-DD', months: 'YYYY-MM' };
    while (moment(startDate) <= moment(endDate)) {
        dates.push(startDate);
        startDate = moment(startDate).add(1, duration).format(format[duration]);
    }
    return dates;
}

export const isLocal = window.location.host.includes('localhost');
export const isStaging = window.location.host.includes('stage-');

export const ucFirst = (str: string) => {
    return str ? str.toLowerCase().charAt(0).toUpperCase() + str.toLowerCase().slice(1) : '';
}

export const ucWords = (str: string) => {
    return str ? str.split(' ').map(str => { return ucFirst(str); }).join(' ') : '';
}

export const businessHasAccess = (business: types.Business, permission: string | undefined) => {
    if (typeof permission === 'undefined') return true;
    if (typeof business.preferences?.permissions === 'undefined') return false;
    if (business.preferences?.permissions.includes(permission)) return true;
    return false;
}

export const businessBalance = (currency: types.Currencies, business: types.Business, env: string) => {
    const anv = (env === 'live' ? '' : '_test');
    return (business.wallets || []).find(w => w.currency === currency)?.[`balance${anv}`] || 0;
}

export const copyObject = (a: any) => JSON.parse(JSON.stringify(a));

export const isMobile = !!(rdc.isMobile && !rdc.isTablet);

export const hasPermission = (role: string) => {
    const user: types.User = JSON.parse(getStorageJson('auth').user || '{}');
    const business: types.Business = JSON.parse(getStorageJson('auth').business || '{}') || {};
    const team = ((user.teams || []).find(t => t.businessId === business.id) || {}) as types.Team;
    if (team.roleId && team.role) {
        return team.role.permissions.includes(role);
    }
    return true;
}

export const requiredInput = [{ required: true, message: 'This field is required' }];

export const KYBCompletion = (business: types.Business) => {
    const details = !!(business.name && business.description && business.industry && business.team_size);
    const address = !!(business.compliance?.address?.line1 && business.compliance?.address?.city && business.compliance?.address?.state && business.compliance?.address?.country);
    const documents = !!((business.compliance?.documents || []).filter(d => d && d.doc_file).length > 0);
    const directors = !!((business.compliance?.directors || []).filter(d => d.id_front && d.id_back).length > 0);
    const shareholders = !!((business.compliance?.shareholders || []).filter(d => d.id_front).length > 0);
    return {
        all: ((+details + +address + +documents + +directors + +shareholders) / 5) * 100,
        details,
        address,
        documents,
        directors,
        shareholders,
    }
}